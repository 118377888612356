import React from 'react'
import BlockText from 'components/ui/block-text'

const meses = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]

const Post = ({ title, subtitle, image, text, createdAt }) => {
  const formatDate = type => {
    if (type === 'day') return createdAt.substr(0, 2)
    if (type === 'month') return meses[createdAt.substr(3, 2) - 1]
    if (type === 'year') return createdAt.substr(6, 4)
  }

  return (
    <div className="container">
      <div
        className="post-img d-flex shadow col-12 my-5"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <p className="post-img--p col-7 mx-4 p-3 mt-auto">{subtitle}</p>
      </div>
      <div className="post d-flex mb-3">
        <div className="postContenido col-10">
          <div className="postContenido-text">
            <h4>{title}</h4>
            <BlockText data={text} />
          </div>
        </div>
        <div className="postFecha col-2">
          <div className="postFecha-fecha text-center py-3 col-8 ms-auto">
            <p className="postFecha-fecha--dia m-0">{formatDate('day')}</p>
            <p className="postFecha-fecha--mes m-0">{formatDate('month')}</p>
            <p className="postFecha-fecha--año m-0">{formatDate('year')}</p>
          </div>
          {/* <div className="postFecha-redes col-8 mt-4 ms-auto">
            <p className="postFecha-redes--p  text-center">Compartir</p>
            <ul className="postFecha-redes--ul">
              <li className="my-3">
                <a className="" href="google.com.co">
                  <img
                    className="postFecha-iconos-redes"
                    src="/linkedin_green.svg"
                    alt="linkedin-icon"
                  />
                </a>
              </li>
              <li className="my-3">
                <a className="" href="google.com.co">
                  <img
                    className="postFecha-iconos-redes"
                    src="/twitter_green.svg"
                    alt="twitter-icon"
                  />
                </a>
              </li>
            </ul>
          </div> */}
        </div>

        <div className="post-cards"></div>
      </div>
    </div>
  )
}

export default Post
