import React from 'react'

import Layout from 'components/layout'
import Hero from 'components/hero'
import Post from 'components/post'

import useHasMounted from '../hooks/useHasMounted'

import { Disqus } from 'gatsby-plugin-disqus'

import { graphql } from 'gatsby'

const PostPage = ({ pageContext, data, location }) => {
  const {
    sanityAppereance: {
      tabsAppereance: { header, footer },
    },
  } = data

  let disqusConfig = {
    url: location.href,
    identifier: pageContext.post?.id,
    title: pageContext.post?.title,
  }

  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  const setCallToActionBtn = () => {
    console.log('click btn contáctanos')
  }

  return (
    <Layout header={header} footer={footer}>
      <Hero
        imageBackground="/oil-and-gas-industry-HESYCW4.jpg"
        title="Son nuestra prioridad."
        subTitle="Siempre con un equipo que entrega mas de lo esperado y donde te conviertes en nuestra prioridad"
        btnText="Contactanos"
        btnCallToAction={() => setCallToActionBtn}
        location={location.pathname}
      />
      <Post
        title={pageContext.post?.title}
        subtitle={pageContext.post?.subtitle}
        image={pageContext.post?.image?.image?.asset?.url}
        text={pageContext.post?._rawText}
        createdAt={pageContext.post?._createdAt}
      />
      <div className="container">
        <Disqus config={disqusConfig} />
      </div>
    </Layout>
  )
}

export const IndexQuery = graphql`
  query PostQuery {
    sanityAppereance(
      slug: { current: { eq: "apariencia" } }
      title: { eq: "apariencia" }
    ) {
      tabsAppereance {
        footer {
          correo1
          correo2
          correo3
          departamento
          enableFooter
          direccion
          footerTitle
          footerParagrah
          logoEmpresaFooter {
            alt
            image {
              asset {
                url
              }
            }
          }
        }
        header {
          correo
          enableHeader
          linkFacebook
          linkInstagram
          linkLinkedin
          linkTwitter
          messenger
          telefono
        }
      }
    }
  }
`

export default PostPage
